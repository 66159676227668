import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toFormattedText, toFormattedLinkedText } from '../../services/formatting';
import Dots from '../../components/Home/v2/Dots';
import {
  colors, fontSizes, margins, mobileThresholdPixels,
  Title2, Subtitle2, ContainerBlock, Column,
} from '../../components/Home/v2/StyledComponents';

const P = styled.p`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  color: ${colors.navy};
  width: 900px;
  margin: ${margins.l} auto 0px;
  text-align: justify;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: ${margins.m} 0px 0px;
  }
`;

const MoreAbout = ({ subTitle, paragraph, links }) => (
  <ContainerBlock id="MoreAbout">
    <Column justifyContent={'center'} alignItems={'center'}>
      <Title2>En savoir plus</Title2>
      <Dots />
      <Subtitle2>{toFormattedText(subTitle)}</Subtitle2>
    </Column>
    <P>{toFormattedLinkedText(paragraph, links)}</P>
  </ContainerBlock>
);

MoreAbout.propTypes = {
  subTitle: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
};

export default MoreAbout;
